import React from 'react';
import './index.scss';
import TextField from '../../../../../../../../design/1/js/templates/molecules/text-field';
import Form from '../../../../../../../../design/1/js/templates/organisms/form';
import SalaryDataType from '../../../lib/entity/SalaryDataType';
import AsyncLoader from '../../../../../../../../design/1/js/templates/molecules/async-loader';
import StatusMessage, {MessageType} from "../../../../../../../../design/1/js/templates/molecules/status-message";
import StatusMessageVariation
    from "../../../../../../../../design/1/js/templates/molecules/status-message/lib/StatusMessageVariation";

interface Props {
    action: string;
    className?: string;
}

const SalaryData = ({
    action,
    className,
}: Props) => {
    const baseClassName = 'w-yourProfile-salaryData';

    return (
        <div className={`${baseClassName} ${className}`}>
            <AsyncLoader
                action={action}
                renderContent={lazyChildren => (
                    <>
                        <h2 id={window.sv_resource.get('plf_yourprofile_salarydata_title')}>{window.sv_resource.get('plf_yourprofile_salarydata_title')}</h2>
                        {lazyChildren}
                    </>
                )}
            >
                {(salaryData: SalaryDataType) => {
                    const initialValues = Object.freeze({
                        insuredSalary: salaryData.insuredSalary.value,
                        grossSalary: salaryData.grossSalary.value,
                        employmentLevel: salaryData.levelOfEmployment?.value ?? null,
                    });

                    return (
                        <Form actionUrl="/" name="salaryForm" initialValues={initialValues} validationSchema={null}>
                            {formik => (
                                <>
                                    <div className="row gap-xxs">
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.grossSalary}
                                            id={salaryData.grossSalary.id}
                                            label={salaryData.grossSalary.label}
                                            name={salaryData.grossSalary.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.grossSalary}
                                            touched={formik.touched.grossSalary}
                                            type="text"
                                            value={formik.values.grossSalary}
                                        />
                                    </div>
                                    {salaryData.levelOfEmployment && (
                                        <div className="row gap-xxs">
                                            <TextField
                                                className={`${baseClassName}__field col-12 col-lg-6`}
                                                disabled
                                                errors={formik.errors.employmentLevel}
                                                id={salaryData.levelOfEmployment.id}
                                                label={salaryData.levelOfEmployment.label}
                                                name={salaryData.levelOfEmployment.id}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                placeholder={formik.values.employmentLevel}
                                                touched={formik.touched.employmentLevel}
                                                type="text"
                                                value={formik.values.employmentLevel !== null ? `${formik.values.employmentLevel} %` : ''}
                                            />
                                        </div>
                                    )}
                                    <div className="row gap-xxs">
                                        <TextField
                                            className={`${baseClassName}__field col-12 col-lg-6`}
                                            disabled
                                            errors={formik.errors.insuredSalary}
                                            id={salaryData.insuredSalary.id}
                                            label={salaryData.insuredSalary.label}
                                            name={salaryData.insuredSalary.id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder={formik.values.insuredSalary}
                                            touched={formik.touched.insuredSalary}
                                            type="text"
                                            value={formik.values.insuredSalary}
                                        />
                                    </div>
                                    <div className="row gap-xxs">
                                        <StatusMessage
                                            customMessageColor={MessageType.Info}
                                            messageType={MessageType.Info}
                                            description={window.sv_resource.get('plf_yourprofile_salarydata_description')}
                                            variation={StatusMessageVariation.WithBackground}
                                        />
                                    </div>
                                </>
                            )}
                        </Form>
                    );
                }}
            </AsyncLoader>
        </div>
    );
};

SalaryData.defaultProps = {
    className: '',
};

export default SalaryData;
